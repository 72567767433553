'use client';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import logoBdDark from '../../../public/images/logos/possums_icon-dark_bg.png';
import { IconMenu2, IconX, IconSearch, IconArrowRight } from '@tabler/icons-react';
import AuthLinks from './AuthLinks';
import GoogleTagManager from '@dr-pam/common-components/Components/Page/GoogleTagManager';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';

export type NavBarProps = {
	gtmId: string;
};

export default function NavBar(props: NavBarProps) {
	const { gtmId } = props;
	const [showNavbar, setShowNavbar] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const pathname = usePathname();
	const params = useSearchParams();
	const router = useRouter();

	const existingSearchQuery = params.get('q');

	const searchInputElRef = useRef<HTMLInputElement>(null);

	const toggleSearch = () => {
		setShowSearch(!showSearch);
		if (!showSearch) {
			document.body.classList.add('search-open');
			searchInputElRef.current?.focus();
		} else {
			document.body.classList.remove('search-open');
		}
	};

	const toggleMenu = () => {
		setShowNavbar(!showNavbar);
		if (!showNavbar) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
		}
	};
	const closeMenu = () => {
		setShowNavbar(false);
		document.body.classList.remove('menu-open');
	};

	useEffect(() => {
		console.log('changed!', pathname, existingSearchQuery);
		// Close the menu whenever the route changes (including search query)
		closeMenu();
		// Also reset isSearching
		setIsSearching(false);
	}, [pathname, existingSearchQuery]);

	const onBrowseProgramClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
		// If we are already on the browse program page, use a search param to indicate to the page to scroll to the top (and do other things)
		if (pathname === '/browse-the-program') {
			event.preventDefault();
			router.replace('/browse-the-program?top');
		}
	};

	const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (searchQuery.trim() !== existingSearchQuery) {
			router.push(`/search?q=${searchQuery}`);
			setIsSearching(true);
		}
	};

	return (
		<header className="navbar dark-bg">
			<GoogleTagManager gtmId={gtmId} />
			<Link className="logo-container" href="/">
				<Image
					src={logoBdDark}
					className="icon-logo"
					alt="Logo - The Possums baby and toddler sleep program."
					priority={true}
				/>
				<p>
					the possums
					<br />
					<span className="emphasised-text">sleep program</span>
				</p>
			</Link>
			<nav>
				<div className={`hamburger-menu ${showNavbar ? 'hidden' : ''}`} onClick={toggleMenu}>
					<IconMenu2 />
				</div>
				<div className={`menu-links ${showNavbar ? 'show' : ''}`}>
					<div className="close-menu" onClick={closeMenu}>
						<IconX size={32} />
					</div>
					<div className="mobile-spacer hidden-on-desktop"></div>
					<Link href="/find-essentials">find essentials</Link>
					<Link href="/browse-the-program" onClick={onBrowseProgramClicked}>
						browse the program
					</Link>
					<Link href="/about-program">about the program</Link>
					<Link href="/speak-to-an-ndc-practitioner">speak to an NDC practitioner</Link>
					<AuthLinks />

					<div className="mobile-spacer hidden-on-desktop"></div>
					<div className="toggle-search" onClick={toggleSearch}>
						<IconSearch className="desktop-only" size={32} />
					</div>
					<form onSubmit={handleSearchSubmit} className="search-form">
						<p>search for articles in the program</p>
						<input
							ref={searchInputElRef}
							type="text"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder="i.e. night waking"
							className="search-input"
							disabled={isSearching}
						/>
						<button
							type="submit"
							className="search-button inline white"
							style={{
								cursor: searchQuery.trim() ? 'pointer' : 'not-allowed',
							}}
							disabled={!searchQuery.trim() || isSearching}
						>
							{isSearching ? <Loading /> : <IconArrowRight size={32} />}
						</button>
					</form>
				</div>
			</nav>
		</header>
	);
}
